import React from 'react';
import { graphql, Link } from 'gatsby';
import { Rows, SiteMetadata, TypeButtons } from '../components';
import { Layout } from '../layouts/Layout';
import AmazonResponsive from '../components/Ads/AmazonResponsive';

export default ({ data, pageContext: { category = 'All', previousPagePath, nextPagePath }, ...props }) => {
  return (
    <Layout category={category}>
      <SiteMetadata
        title={category}
        description={`${category} streamings that are being offered online for free, or for a small donation fee, during the COVID-19 pandemic`}
        path={props.location.pathname}
      />
      <Rows nodes={data.items.nodes} />
      <div className="container pt-4 pb-12 overflow-hidden justify-center align-center flex leading-tight text-white">
        {previousPagePath && <Link to={previousPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          « Previous
        </Link>}
        {nextPagePath && <Link to={nextPagePath} className="inline-block rounded-lg px-4 py-3 bg-indigo-600 hover:bg-indigo-500 font-semibold shadow-lg mx-2">
          Next »
        </Link>}
      </div>
      <AmazonResponsive />
      <TypeButtons title="You might also be interested in..."></TypeButtons>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PaginatedFilmPageQuery($tableName: String, $category: String, $skip: Int!, $limit: Int!) {
    items: allAirtable(
      filter: {
        table: { eq: $tableName }
        data: { category: { eq: $category } }
      }
      sort: { fields: data___lastModified, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        data {
          tags
          image {
            ...HorizontalCardImageFragment
          }
          name
          url
          summary
        }
      }
    }
  }
`;
